<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="6">
        <b-card class="card">
          <template #header>
            <h6 class="mb-0">Tần suất phân bổ chỉ số huyết áp</h6>
          </template>
          <b-overlay :show="distributionLoading">
            <div style="height: 400px; margin: auto">
              <v-chart :option="donutOptions" autoresize />
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="card h-100">
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center"
              style="width: 100%"
            >
              <h6 class="mb-0">Xu hướng huyết áp</h6>
            </div>
          </template>
          <b-overlay :show="trendPressureLoading">
            <div class="chart-container">
              <apexchart
                class="card-rounded-bottom"
                :options="doubleLineOptions"
                :series="doubleLineSeries"
                type="line"
              ></apexchart>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card class="card">
          <template #header>
            <h6 class="mb-0">Xu hướng nhịp tim</h6>
          </template>
          <b-overlay :show="trendRateLoading">
            <div class="chart-container">
              <apexchart
                class="card-rounded-bottom"
                :options="singleLineOptions"
                :series="singleLineSeries"
                type="line"
              ></apexchart>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import VChart from 'vue-echarts';
use([CanvasRenderer, PieChart]);
export default {
  name: 'BloodPressureTab',
  components: {
    VChart,
  },
  props: {
    userId: {
      type: String,
    },
    selectDay: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      singleLineOptions: {
        chart: {
          width: 300,
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        colors: ['#01645A', '#B5B5C3'],

        stroke: {
          width: 1,
          curve: 'straight',
        },
        markers: {
          strokeColors: ['#01645A', '#B5B5C3'],
          colors: ['#01645A', '#B5B5C3'],
          shape: 'circle',
          size: 2,
        },

        tooltip: {
          // shared: true,
          y: {
            formatter: function (val) {
              return val + ' lần/phút';
            },
          },
        },
      },
      doubleLineOptions: {
        chart: {
          width: 300,
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        colors: ['#01645A', '#B5B5C3'],

        stroke: {
          width: 1,
          curve: 'straight',
        },
        markers: {
          strokeColors: ['#01645A', '#B5B5C3'],
          colors: ['#01645A', '#B5B5C3'],
          shape: 'circle',
          size: 2,
        },

        tooltip: {
          shared: true,
          y: {
            formatter: function (val) {
              return val + ' mm/Hg';
            },
          },
        },
      },
      doubleLineSeries: [
        {
          color: '#666666',
          name: 'Tâm thu',
          data: [],
        },
        {
          color: '#008479',
          name: 'Tâm trương',
          data: [],
        },
      ],
      singleLineSeries: [
        {
          name: 'Nhịp tim',
          data: [],
        },
      ],

      donutOptions: {
        height: 350,

        label: {
          formatter: '{b}  {d}%',
        },
        labelLine: {
          smooth: 0.2,
          length: 15,
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: this.selectDay,
        takeAll: true,
      },
      distributionLoading: false,
      trendPressureLoading: false,
      trendRateLoading: false,
    };
  },
  watch: {
    selectDay: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal;
        this.loadData();
      },
    },
  },
  computed: {
    patientId() {
      return this.userId;
    },
  },
  methods: {
    async loadDistributionData() {
      this.distributionLoading = true;
      try {
        let { data } = await await this.$api.get(
          'Admin/BloodPressure/Distribution',
          {
            params: { ...this.searchParams, patientId: this.patientId },
          },
        );
        let series = [
          {
            value: data.low > 0 ? data.low : null,
            itemStyle: { color: data.lowColor },
            name: 'Thấp',
          },
          {
            value: data.normal > 0 ? data.normal : null,
            itemStyle: { color: data.normalColor },
            name: 'Bình thường',
          },
          {
            value: data.preIncrease > 0 ? data.preIncrease : null,
            itemStyle: { color: data.preIncreaseColor },
            name: 'Bình thường cao',
          },
          {
            value: data.increaseLevelOne > 0 ? data.increaseLevelOne : null,
            itemStyle: { color: data.increaseLevelOneColor },
            name: 'Tăng huyết áp độ 1 ',
          },
          {
            value: data.increaseLevelTwo > 0 ? data.increaseLevelTwo : null,
            itemStyle: { color: data.increaseLevelTwoColor },
            name: 'Tăng huyết áp độ 2 ',
          },
          {
            value: data.increaseLevelThree > 0 ? data.increaseLevelThree : null,
            itemStyle: { color: data.increaseLevelThreeColor },
            name: 'Tăng huyết áp độ 3 ',
          },
        ];
        this.donutOptions.series[0].data = series;
        this.distributionLoading = false;
      } catch (error) {
        this.distributionLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadTrendHeartRate() {
      this.trendRateLoading = true;
      try {
        let { data } = await this.$api.get(
          'Admin/BloodPressure/Trend/PulseRate',
          {
            params: { ...this.searchParams, patientId: this.patientId },
          },
        );
        let series = data.trendItems?.items.map((e) => ({
          x: this.$moment(e.date * 1000).format('DD/MM/YYYY ddd'),
          y: e.subTrendItems[0]?.pulseRate,
        }));
        this.singleLineSeries = [{ data: series }];
        this.trendRateLoading = false;
      } catch (error) {
        this.trendRateLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadTrendBloodPressure() {
      this.trendPressureLoading = true;
      try {
        let { data } = await this.$api.get('Admin/BloodPressure/Trend', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        let diastolic = [];
        let systolic = [];
        for (const el of data.trendItems.items) {
          diastolic.push({
            y: el.subTrendItems[0]?.diastolic,
            x: this.$moment(el.date * 1000).format('DD/MM/YYYY ddd'),
          });
          systolic.push({
            y: el.subTrendItems[0]?.systolic,
            x: this.$moment(el.date * 1000).format('DD/MM/YYYY ddd'),
          });
        }
        this.doubleLineSeries = [{ data: systolic }, { data: diastolic }];
        this.trendPressureLoading = false;
      } catch (error) {
        this.trendPressureLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    loadData() {
      this.loadDistributionData();
      this.loadTrendHeartRate();
      this.loadTrendBloodPressure();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style></style>
