<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-overlay :show="bmiLoading">
          <b-card class="card">
            <template #header>
              <h6 class="mb-0">Chỉ số BMI</h6>
              <div class="d-flex justify-content-between mt-1">
                <div class="bmi__statistic">
                  <p class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ weight }}</span
                    >kg
                  </p>
                  <p>Cân nặng</p>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic bmi__statistic-green">
                  <p class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ bmi }}</span>
                  </p>
                  <p>BMI ({{ bmiText }})</p>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic">
                  <p class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ height }}</span
                    >cm
                  </p>
                  <p>Chiều cao</p>
                </div>
              </div>
            </template>
            <div style="height: 350px">
              <v-chart :option="option" autoresize ref="gauge" />
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay :show="weightLoading">
          <b-card class="card">
            <template #header>
              <h6 class="mb-0">Xu hướng cân nặng</h6>
              <div class="d-flex justify-content-between mt-1">
                <div class="bmi__statistic">
                  <p class="mb-1 text-center">Nhẹ nhất</p>
                  <p>
                    <span class="bmi__statistic-bold">{{ lowestWeight }}</span>
                    kg
                  </p>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic">
                  <p class="mb-1 text-center">Nặng nhất:</p>
                  <p>
                    <span class="bmi__statistic-bold">{{ highestWeight }}</span>
                    kg
                  </p>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic">
                  <p>Mục tiêu</p>
                  <p class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ goalWeight }}</span> kg
                  </p>
                </div>
              </div>
            </template>
            <div class="bmiChartValue" style="max-width: 450px">
              <div style="height: 350px; margin: auto">
                <v-chart :option="weightLineOptions" autoresize ref="line" />
              </div>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay :show="waistLoading">
          <b-card class="card">
            <template #header>
              <h6 class="mb-0">Xu hướng vòng eo</h6>
              <div class="d-flex justify-content-between mt-1">
                <div class="bmi__statistic">
                  <p>Nhỏ nhất</p>
                  <div class="mb-1 text-center">
                    <b class="bmi__statistic-bold">{{ lowestWaist }}</b> cm
                  </div>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic">
                  <p>Lớn nhất:</p>
                  <div class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ highestWaist }}</span>
                    cm
                  </div>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic">
                  <p>Hiện tại</p>
                  <div class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ currentWaist }}</span>
                    cm
                  </div>
                </div>
                <span class="divide"></span>
                <div class="bmi__statistic">
                  <p>Mục tiêu</p>
                  <div class="mb-1 text-center">
                    <span class="bmi__statistic-bold">{{ goalWaist }}</span>
                    cm
                  </div>
                </div>
              </div>
            </template>
            <div style="height: 350px; margin: auto">
              <v-chart :option="waistLineOptions" autoresize ref="line" />
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
} from 'echarts/components';
import { GaugeChart, LineChart } from 'echarts/charts';
import VChart from 'vue-echarts';

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  GridComponent,
  LineChart,
  GaugeChart,
  CanvasRenderer,
]);
export default {
  name: 'WeightTab',
  components: {
    VChart,
  },
  props: {
    userId: {
      type: String,
    },
    selectDay: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      waistLineOptions: {
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
        },
        itemStyle: {
          borderType: 'dotted',
        },

        series: [
          {
            data: [],
            type: 'line',
            symbolSize: 5,
            symbol: 'circle',
            lineStyle: {
              color: '#888C9F',
              width: 1,
            },
            markLine: {
              silent: true,
              symbol: 'none',
              lineStyle: {
                type: 'solid',
                color: '#21A567',
              },
              data: [],
            },
          },
        ],
      },

      weightLineOptions: {
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            data: [],
            type: 'line',
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: '#888C9F',
              width: 1,
            },

            markLine: {
              symbol: 'none',
              silent: true,
              lineStyle: {
                color: '#21A567',
                type: 'solid',
              },
              data: [],
            },
          },
        ],
      },
      option: {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 15,
            max: 35,
            splitNumber: 6,
            axisLine: {
              lineStyle: {
                width: 0,
                color: [
                  [0.2, '#F58220'],
                  [0.4, '#21A567'],
                  [0.6, '#FFE3E3'],
                  [0.8, '#FF8E8E'],
                  [1, '#E53935'],
                ],
              },
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '50%',
              width: 10,
              offsetCenter: [0, '0%'],
              itemStyle: {
                color: '#01645A',
              },
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 10,
              itemStyle: {
                color: '#EFEFEF',
              },
            },
            axisTick: {
              length: 25,
              lineStyle: {
                type: 'solid',
                color: 'auto',
                width: 3,
              },
            },
            axisLabel: {
              color: '#666',
              fontSize: 14,
              distance: -60,
              formatter: function (value) {
                let data = Math.round(value);
                if (data == 18) {
                  return '18.5';
                } else if (data == 22) {
                  return '23';
                } else if (data == 28) {
                  return '25';
                } else if (data == 32) {
                  return '30';
                }
              },
            },
            title: {
              offsetCenter: [0, '-20%'],
              fontSize: 30,
            },
            detail: {
              show: false,
              fontSize: 50,
              offsetCenter: [0, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return value;
              },
              color: 'auto',
            },
            data: [],
          },
        ],
      },
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: this.selectDay,
        takeAll: true,
      },
      highestWeight: null,
      lowestWeight: null,
      goalWeight: null,
      highestWaist: null,
      lowestWaist: null,
      goalWaist: null,
      currentWaist: null,
      bmiText: null,
      bmi: null,
      weight: 0,
      height: 0,
      bmiLoading: false,
      weightLoading: false,
      waistLoading: false,
    };
  },
  watch: {
    selectDay: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal;
        this.loadData();
      },
    },
  },
  computed: {
    patientId() {
      return this.userId;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadBMI() {
      this.option.series[0].data = [];
      this.bmiLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Weight/Statistic/Bmi', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        let bmiChartValue = data.value;
        this.bmi = bmiChartValue;
        this.bmiText = data.currentLedend?.text;
        this.weight = data.weight;
        this.height = data.height;
        if (bmiChartValue > 23 && bmiChartValue < 24) {
          bmiChartValue = bmiChartValue * 1.05;
        } else if (bmiChartValue >= 24 && bmiChartValue < 25) {
          bmiChartValue = bmiChartValue + 1.75;
        } else if (bmiChartValue == 25) {
          bmiChartValue = bmiChartValue + 2.5;
        } else if (bmiChartValue > 25 && bmiChartValue <= 28) {
          bmiChartValue = bmiChartValue + 2.5;
        } else if (bmiChartValue > 28 && bmiChartValue < 30) {
          bmiChartValue = bmiChartValue + 2;
        } else if (bmiChartValue >= 30) {
          bmiChartValue = bmiChartValue + 1.5;
        }

        this.option.series[0].data.push({
          value: bmiChartValue || 0,
        });
        this.$refs.gauge?.setOption(this.option);
        this.bmiLoading = false;
      } catch (error) {
        this.bmiLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadWeight() {
      this.weightLineOptions.xAxis.data = [];
      this.weightLineOptions.series[0].data = [];
      this.weightLineOptions.series[0].markLine.data = [];
      this.weightLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Weight/Statistic/Weight', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.highestWeight = data.highest;
        this.lowestWeight = data.lowest;
        this.goalWeight = data.goal;
        this.weightLineOptions.series[0].markLine.data.push({
          name: 'Muc tieu',
          yAxis: data.goal || 0,
        });
        if (data.trendItems && data.trendItems.length) {
          data.trendItems.forEach((e) => {
            this.weightLineOptions.xAxis.data.push(
              this.$moment(e.date * 1000).format('DD/MM/YYYY'),
            );
            this.weightLineOptions.series[0].data.push({
              value: e.value,
              itemStyle: {
                color: e.colorCode,
              },
            });
          });
        }
        this.weightLoading = false;
      } catch (error) {
        this.weightLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadWaist() {
      this.waistLineOptions.xAxis.data = [];
      this.waistLineOptions.series[0].data = [];
      this.waistLineOptions.series[0].markLine.data = [];
      this.waistLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Weight/Statistic/Waist', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.highestWaist = data.highest;
        this.lowestWaist = data.lowest;
        this.goalWaist = data.goal;
        this.currentWaist = data.current;
        this.waistLineOptions.series[0].markLine.data.push({
          name: 'Muc tieu',
          yAxis: data.goal || 0,
        });
        if (data.trendItems && data.trendItems.length) {
          data.trendItems.forEach((e) => {
            this.waistLineOptions.xAxis.data.push(
              this.$moment(e.date * 1000).format('DD/MM/YYYY'),
            );
            this.waistLineOptions.series[0].data.push({
              value: e.value,
              itemStyle: {
                color: e.colorCode || '#21A567',
              },
            });
          });
        }
        this.waistLoading = false;
      } catch (error) {
        this.waistLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    loadData() {
      this.loadWeight();
      this.loadWaist();
      this.loadBMI();
    },
  },
};
</script>
<style lang="scss">
div.echarts {
  width: 100%;
  width: 300px;
  min-width: 300px;
  height: 300px;
}
</style>
<style lang="scss" scoped>
.bmi__statistic {
  text-align: center;
  padding: 1rem;
  &-border {
    &:not(:last-child) {
      border-right: 1px solid #ebedf3;
    }
  }
  &-green {
    color: #21a567;
  }
  &-bold {
    font-weight: bold;
    font-size: 1.3rem;
  }
}
.divide {
  width: 1px;
  background-color: #ebedf3;
}
</style>
